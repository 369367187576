import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPageWrapper = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <Container>
      <Row>
        <NotFoundPageWrapper>
          <div>
            <h1>NIE ZNALEZIONO</h1>
            <p>Właśnie wybrałeś trasę, która nie istnieje... smutek.</p>
          </div>
        </NotFoundPageWrapper>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
